import React from "react";
import Example from "../components/Example";
import Test from "../components/Test";
import '../Media-queries.css';
// import Work from '../components/Work'


function Blog() {
  return (
    <>
     {/* <Example /> */}
     {/* <Work /> */}
     <Test />
    </>
  );
}

export default Blog;
