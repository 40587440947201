import React from "react";
import BigFeatures from "../components/BigFeatures";
import '../Media-queries.css';


function Services() {
  return (
    <>
      <BigFeatures />
    </>
  );
}

export default Services;
