import React from "react";
import Features from "../components/Features";
import '../Media-queries.css';


function About() {
  return (
    <>
      <Features />
    </>
  );
}

export default About;
